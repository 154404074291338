import React, { useRef, useEffect } from 'react';
import './Room1.scss'; // Import SCSS styles for the Room2 component

const Room2 = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    // Setup
    const canvas = canvasRef.current;
    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    // Scene and camera setup
    const scene = new window.THREE.Scene();
    const camera = new window.THREE.PerspectiveCamera(10, sizes.width / sizes.height, 0.1, 100);
    camera.position.x = 18;
    camera.position.y = 8;
    camera.position.z = 20;
    scene.add(camera);

    // Renderer setup
    const renderer = new window.THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
      alpha: true
    });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.outputEncoding = window.THREE.sRGBEncoding;

    // Controls setup
    const controls = new window.THREE.OrbitControls(camera, canvas);
    controls.enableDamping = true;
    controls.enableZoom = true;
    controls.enablePan = false;
    controls.minDistance = 20;
    controls.maxDistance = 40;
    controls.minPolarAngle = Math.PI / 4;
    controls.maxPolarAngle = Math.PI / 2;
    controls.minAzimuthAngle = - Math.PI / 80;
    controls.maxAzimuthAngle = Math.PI / 2.5;

    // Materials setup
    const textureLoader = new window.THREE.TextureLoader();
    const bakedTexture = textureLoader.load('https://rawcdn.githack.com/ricardoolivaalonso/ThreeJS-Room05/ae27bdffd31dcc5cd5a919263f8f1c6874e05400/baked.jpg');
    bakedTexture.flipY = false;
    bakedTexture.encoding = window.THREE.sRGBEncoding;

    const bakedMaterial = new window.THREE.MeshBasicMaterial({
      map: bakedTexture,
      side: window.THREE.DoubleSide,
    });

    // Loader setup
    const loader = new window.THREE.GLTFLoader();
    loader.load('https://rawcdn.githack.com/ricardoolivaalonso/ThreeJS-Room05/ae27bdffd31dcc5cd5a919263f8f1c6874e05400/model.glb',
      (gltf) => {
        const model = gltf.scene;
        model.traverse(child => child.material = bakedMaterial);
        scene.add(model);
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total * 100) + '% loaded');
      }
    );

    // Resize handling
    const handleResize = () => {
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    };

    window.addEventListener('resize', handleResize);

    // Animation loop
    const animate = () => {
      controls.update();
      renderer.render(scene, camera);
      window.requestAnimationFrame(animate);
    };

    animate();

    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="room1">
      <canvas ref={canvasRef} className="webgl" />
    </div>
  );
};

export default Room2;

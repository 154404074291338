// Import statements remain unchanged
import React, { useState } from 'react';
import Menu from './Menu';
import Logo from './Logo';
import House from './House';
import Popup from './Popup';
import About from './About';
import Services from './Services';
import Contact from './Contact';
import './App.scss';
import VideoPopup from './VideoPopup';

const App = () => {
  const [openPopup, setOpenPopup] = useState('');

  const handleOpenPopup = (popupName) => {
    setOpenPopup(popupName);
  };

  const handleClosePopup = () => {
    setOpenPopup('');
  };

  return (
    <div className="app">
      <Menu onOpenPopup={handleOpenPopup} />
      <div className="logo-container">
        <Logo />
      </div>
      <House />
      <div className="content-container">
        <h2><span>Rethink Real Estate:</span> Fractional investing, decentralized funding & permissionless self-governing RWAs via ERC404</h2>
      </div>
      <div className="button-container">
        <button className="button" onClick={() => window.open('http://rooms.404.estate', '_blank')}>Explore Estate</button>
        <button className="button" onClick={() => handleOpenPopup('watchTrailer')}>
          🎥 Watch Trailer
        </button>
      </div>
      {/* Conditionally render popups based on openPopup state */}
      {openPopup === 'exploreHouses' && <Popup onClose={handleClosePopup} />}
      {openPopup === 'about' && <About onClose={handleClosePopup} />}
      {openPopup === 'services' && <Services onClose={handleClosePopup} />}
      {openPopup === 'contact' && <Contact onClose={handleClosePopup} />}
      {/* Adding the VideoPopup component conditionally */}
      {openPopup === 'watchTrailer' && <VideoPopup onClose={handleClosePopup} />}
    </div>
  );
};

export default App;

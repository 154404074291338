import React, { useRef, useEffect } from 'react';
import './Room1.scss'; // Import SCSS styles for the Popup component

const Room1 = ({ onClose, onNext, onBack }) => {
  const canvasRef = useRef(null);
  const scene = new window.THREE.Scene();
  const camera = new window.THREE.PerspectiveCamera(30, window.innerWidth / window.innerHeight, 0.1, 100);
  const renderer = new window.THREE.WebGLRenderer({ antialias: true, alpha: true });
  const controls = new window.THREE.OrbitControls(camera, renderer.domElement);

  useEffect(() => {
    const canvas = canvasRef.current;
    const textureLoader = new window.THREE.TextureLoader();
    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    // Camera
    camera.position.set(18, 10, 20);
    scene.add(camera);

    // Renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.outputEncoding = window.THREE.sRGBEncoding;
    canvas.appendChild(renderer.domElement);

    // Controls
    controls.enableDamping = true;
    controls.enableZoom = true;
    controls.enablePan = false;
    controls.minDistance = 10;
    controls.maxDistance = 20;
    controls.minPolarAngle = Math.PI / 4;
    controls.maxPolarAngle = Math.PI / 2.25;
    controls.minAzimuthAngle = -Math.PI / 16;
    controls.maxAzimuthAngle = Math.PI / 2;

    // Materials
    const bakedTexture = textureLoader.load('https://rawcdn.githack.com/ricardoolivaalonso/ThreeJS-Room01/98fd8d7909308ec03a596928a394bb25ed9239f1/baked.jpg');
    bakedTexture.flipY = false;
    bakedTexture.encoding = window.THREE.sRGBEncoding;

    const bakedMaterial = new window.THREE.MeshBasicMaterial({
      map: bakedTexture,
      side: window.THREE.DoubleSide,
    });

    // Loader
    const loader = new window.THREE.GLTFLoader();
    loader.load('https://rawcdn.githack.com/ricardoolivaalonso/ThreeJS-Room01/98fd8d7909308ec03a596928a394bb25ed9239f1/THREEJS2.glb',
      (gltf) => {
        const model = gltf.scene;
        model.traverse(child => child.material = bakedMaterial);
        scene.position.set(0, -2, 0);
        scene.add(model);
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total * 100) + '% loaded');
      }
    );

    // Resize handling
    const handleResize = () => {
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    };

    window.addEventListener('resize', handleResize);

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };

    animate();

    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="room1">
      <div ref={canvasRef} className="webgl"></div>
      
    </div>
  );
};

export default Room1;

import React from 'react';
import './Contact.scss'; // Ensure this file contains the styles
import './Popup.scss';

const Contact = ({ onClose }) => (
  <div className="popup">
    <div className="popup-header">
    <div className="popup-title">Contact us</div>

      <button className="popup-close-button" onClick={onClose}>X</button>
    </div>
    <div className="popup-content">
      <h2>Contact Us</h2>
      <p>If you have any questions or need further information, please don't hesitate to contact us.</p>
      <form className="contact-form">
        <label htmlFor="name">Name</label>
        <input type="text" id="name" name="name" required />
        
        <label htmlFor="email">Email</label>
        <input type="email" id="email" name="email" required />
        
        <label htmlFor="message">Message</label>
        <textarea id="message" name="message" rows="4" required></textarea>
        
        <button type="submit" className="send-message-button">Send Message</button>
      </form>
    </div>
  </div>
);

export default Contact;

import React, { useState } from 'react';
import './Popup.scss'; // Import SCSS styles for the Popup component
import Room1 from './Room1'; // Import the Room1 component
import Room2 from './Room2'; // Import the Room2 component, assuming you have one

const Popup = ({ onClose }) => {
  const [currentRoom, setCurrentRoom] = useState(1); // State to track the current room

  const handleNextRoom = () => {
    setCurrentRoom((prevRoom) => (prevRoom === 1 ? 2 : 1)); // Toggle between Room1 and Room2
  };

  const handlePreviousRoom = () => {
    setCurrentRoom((prevRoom) => (prevRoom === 2 ? 1 : 2)); // Toggle between Room2 and Room1
  };

  return (
<div className="popup">
  <div className="navigation-controls">
    <button className="nav-btn back-btn" onClick={handlePreviousRoom}>Back</button>
    <button className="nav-btn next-btn" onClick={handleNextRoom}>Next</button>
    <button className="close-btn" onClick={onClose}>Close</button>
  </div>
  <div className="popup-content">
    {currentRoom === 1 ? <Room1 /> : <Room2 />}
  </div>
</div>

  );
};



export default Popup;

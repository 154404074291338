import React from 'react';
import './Popup.scss';

const Services = ({ onClose }) => (
  <div className="popup">
    <div className="popup-header">
      <div className="popup-title">Services</div>
      <button className="popup-close-icon" onClick={onClose}>X</button>
    </div>
    <div className="popup-content">
      <h2> 404 Services</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Purus in mollis nunc sed id.</p>
      
      <h3>Our Services #1</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Purus in mollis nunc sed id.</p>
      
      <h3>Our Services #2</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Purus in mollis nunc sed id.</p>
      
      <h3>Our Services #3</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Purus in mollis nunc sed id.</p>
      
      <h3>OurServices #4</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Purus in mollis nunc sed id.</p>

    </div>
  </div>
);

export default Services;

import React from 'react';
import { useDynamicGlitchEffect } from './useDynamicGlitchEffect'; // Adjust the import path as needed
import './Logo.scss';

const Logo = () => {
  const glitchedText = useDynamicGlitchEffect("404.estate");

  return (
    <div className="logo-glitch">
      {glitchedText}
    </div>
  );
};

export default Logo;

import React from 'react';
import './VideoPopup.scss'; // Make sure this import path is correct

const VideoPopup = ({ onClose }) => (
  <>
    <div className="video-popup-backdrop" onClick={onClose}></div>
    <div className="video-popup">
      <button className="close-button" onClick={onClose}>✖</button>
      <iframe
        src="https://www.youtube.com/embed/your_video_id" // Replace "your_video_id" with the actual ID
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  </>
);

export default VideoPopup;

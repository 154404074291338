// Menu.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faDiscord, faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './Menu.scss'; // Assuming you will create a separate SCSS file for menu styles
import { faBook } from '@fortawesome/free-solid-svg-icons';

const Menu = ({ onOpenPopup }) => { // Assuming onOpenPopup prop is passed down from App.js
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleMenu = () => setIsOpen(!isOpen);
  
    return (
      <nav className="navbar">
        <div className="navbar-left">
          <a href="https://docs.404.estate" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faBook} /> Docs</a>
        </div>
        <div className="navbar-toggle" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faBars} />
        </div>
        <ul className={`navbar-links ${isOpen ? 'active' : ''}`} onClick={() => setIsOpen(false)}>
          <li><a href="#" onClick={() => onOpenPopup('about')}>About</a></li>
          <li><a href="#" onClick={() => onOpenPopup('services')}>Services</a></li>
          <li><a href="#" onClick={() => onOpenPopup('contact')}>Contact</a></li>
        </ul>
        <div className="navbar-socials">
          <a href="https://twitter.com"><FontAwesomeIcon icon={faTwitter} /></a>
          <a href="https://discord.com"><FontAwesomeIcon icon={faDiscord} /></a>
          <a href="https://telegram.org"><FontAwesomeIcon icon={faTelegramPlane} /></a>
        </div>
      </nav>
    );
  };
  
  export default Menu;

import React from 'react';
import './Popup.scss';

const About = ({ onClose }) => (
  <div className="popup">
    <div className="popup-header">
      <div className="popup-title">About Us</div>
      <button className="popup-close-icon" onClick={onClose}>X</button>
    </div>
    <div className="popup-content">
      <h2>Welcome to 404 Estate</h2>
      <p>At 404 Estate, we're leveraging blockchain to innovate in real estate investment and management. Our mission is to democratize property investment through the ERC404 standard, enabling fractional ownership and ensuring transparency and efficiency.</p>
      
      <h3>Our Vision</h3>
      <p>To make real estate investment accessible and secure worldwide, using ERC404 to provide a seamless, interoperable ecosystem for transparent and easy investment.</p>
      
      <h3>Our Technology</h3>
      <p>Our platform is built on decentralized finance (DeFi) principles, using smart contracts to ensure transparency and security in real estate transactions.</p>
      
      <h3>Our Approach</h3>
      <p>We prioritize trust and security, conducting rigorous KYC and property inspections to ensure quality investments for our community.</p>
      
      <h3>Real404nomics</h3>
      <p>Our economic model, 'real404nomics,' is designed to maximize investor returns, offering revenue sharing and bonuses for full share ownership.</p>
      
      <h3>The 404 Estate Difference</h3>
      <p>We're setting a new standard in real estate management, with a platform that supports open, secure profit distribution via blockchain.</p>
      
      <h3>Join Our Community</h3>
      <p>At 404 Estate, we're more than a platform — we're a community committed to transforming real estate investment. Join us in shaping the future.</p>
    </div>
  </div>
);

export default About;
